<template>
  <v-row class="rowDialog">
    <v-dialog  v-model="dialog" :fullscreen="$vuetify.breakpoint.smAndDown"  max-width="430" >
      <template v-slot:activator="{ on }">
        <v-icon
          v-on="on"
          @click="openModal"
          class="delete-icon ">
          mdi-delete
        </v-icon>
      </template>
      <v-card class="contentCard">
        <v-system-bar color="white" height="40">
          <v-spacer></v-spacer>
            <v-icon size="24" @click="dialog = false" v-ripple>
              mdi-close
            </v-icon>
        </v-system-bar>

        <v-card-title class="headline pt-0">
            Deletar Fornecedor
        </v-card-title>
        <v-divider class="mx-4"></v-divider>
        <v-col>
          <v-alert class="alert" dense outlined v-if="error">{{error}}</v-alert>
        </v-col>
        <v-card-text>
         <v-container class="contentForm" v-if="showModal" fluid>
          <v-form  v-if="!deleteSuppliers" ref="form" v-model="isFormValid"  @submit.prevent="submit">
            <p class="text-justify">
              Tem certeza que deseja excluir?
            </p>
            <v-row v-if="!selectValue">
              <v-col cols="12" class="iconSearchFilters">
                <v-text-field
                  v-model="item.document"
                  label="CNPJ"
                  v-mask="'##.###.###/####-##'"
                  readonly
                ></v-text-field>

                <v-text-field
                  v-model="item.seller"
                  label="Fornecedor"
                  readonly
                ></v-text-field>
              </v-col>
             </v-row>
          </v-form>
          </v-container>
          <v-row class="success-card" v-if="deleteSuppliers">
            <v-col cols="12">
              <img class="imgSuccess" src="@/assets/success.png" />
              <h5 class="succesTitle">Fornecedor excluído com sucesso</h5>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="!deleteSuppliers"
            @click="closeModal"
            class="btnCancel">
            Não
          </v-btn>
          <v-btn
            v-if="!deleteSuppliers"
            @click="sendRequest"
            class="btnRequest"
            id="testeQA"
            :loading="btnLoading"
            :disabled="!isFormValid">
            Sim
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { mask } from 'vue-the-mask';
import service from '../../../service';

export default {
  directives: {
    mask,
  },

  props: {
    item: {
      type: Object,
      required: true,
    },
    reloadGrid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      btnLoading: false,
      showModal: true,
      dialog: false,
      singleStatus: true,
      isFormValid: false,
      selectValue: false,
      service,
      statusOptions: [
        { text: 'Sim', value: true },
        { text: 'Não', value: false },
      ],
    };
  },
  watch: {
    dialog(value) {
      if (value === false) {
        this.closeModal();
      }
    },
  },
  computed: {
    ...mapGetters({
      deleteSuppliers: 'relatedCustumer/getDeleteSuppliers',
      error: 'relatedCustumer/getError',
    }),
  },
  methods: {
    ...mapActions({
      setDeleteSuppliers: 'relatedCustumer/setDeleteSuppliers',
      setRelatedCustumers: 'relatedCustumer/setRelatedCustumers',
      validateLogin: 'Auth/validateLogin',
    }),
    ...mapMutations({
      clearerror: 'relatedCustumer/CLEAR_ERROR',
      cleardeleteSuppliers: 'relatedCustumer/CLEAR_DELETE_SUPPLIERS',
    }),
    closeModal() {
      this.dialog = false;
      this.selectValue = false;
      this.showModal = false;
      this.document = '';
      this.item = { ...this.item };
      this.clearerror();
      this.cleardeleteSuppliers();
    },
    openModal() {
      this.showModal = true;
    },
    async sendRequest() {
      this.btnLoading = true;
      if (this.$refs.form.validate()) {
        await this.setDeleteSuppliers(
          [
            {
              document: this.service.cleanDocument(this.item.document),
            },
          ],
        );
      }
      this.$bus.$emit('reloadPagination', 1);
      this.$bus.$emit(this.reloadGrid, { page: 1 });
      this.btnLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/seller/deleteSuppliers.scss'
</style>
