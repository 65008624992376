<template>
    <v-container>
      <Filters :status="statusFilter" v-if="$vuetify.breakpoint.smAndDown" />
      <v-row>
        <v-col class="colNewSolicitationSm" cols="12">
          <NewSupplier v-if="$vuetify.breakpoint.smAndDown" />
        </v-col>
      </v-row>
      <v-row>
        <v-spacer></v-spacer>
        <v-col md="3" offset-md="12" class="colSolicitation" v-show="showTable">
          <NewSupplier :reloadGrid="'paginationMySuppliers'" v-if="$vuetify.breakpoint.mdAndUp" />
        </v-col>
        <v-col md="12" cols="12">
          <v-divider v-if="$vuetify.breakpoint.mdAndUp"
          class="mx-4 divider divider-consult"></v-divider>
        </v-col>
      </v-row>
      <v-row v-show="showTable">
        <v-col class="tableImport" cols="12">
          <v-data-table
            hide-default-footer
            :headers="headers"
            :items="dataTableItems"
            :items-per-page="10"
            :mobile-breakpoint="600"
            class="dataTableContent"
          >
            <template v-slot:item.status="{ item }">{{ status[item.status.text] }}</template>
            <template v-slot:item.balance="{ item }">
              {{ item.balance ? 'Sim' : 'Não' }}
            </template>

            <template v-slot:item.actions="{ item }">
              <EditSupplier :reloadGrid="'paginationMySuppliers'" :item="item"></EditSupplier>
            </template>

            <template v-slot:item.delete="{ item }">
              <DeleteSupplier :reloadGrid="'paginationMySuppliers'" :item="item"></DeleteSupplier>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row class="pagination" v-show="showTable">
        <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
        <v-col class="colPagination" md="8" cols="12">
          <Pagination :functionEmit="'paginationMySuppliers'" :pages="totalPages" />
        </v-col>
      </v-row>
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Pagination from '../../../components/Pagination.vue';
import service from '../../../service';
import status from '../consts';
import NewSupplier from './NewSupplier.vue';
import EditSupplier from './EditSupplier.vue';
import DeleteSupplier from './DeleteSupplier.vue';

export default {
  name: 'MySuppliers',

  components: {
    Pagination,
    NewSupplier,
    EditSupplier,
    DeleteSupplier,
  },
  data: () => ({
    showTable: true,
    headers: [
      {
        text: 'CNPJ',
        align: 'center',
        filterable: false,
        value: 'document',
      },
      {
        text: 'Fornecedor',
        align: 'center',
        filterable: false,
        value: 'seller',
      },
      {
        text: 'Mostrar Saldo',
        align: 'center',
        filterable: false,
        value: 'balance',
      },
      {
        text: 'Ações',
        align: 'center',
        filterable: false,
        value: 'actions',
      },
      {
        text: 'Deletar',
        align: 'center',
        filterable: false,
        value: 'delete',
      },
    ],
    service,
    status,
    statusFilter: [
      { text: 'Sim', value: true },
      { text: 'Não', value: false },
    ],
  }),
  computed: {
    ...mapGetters({
      getRelatedCustumers: 'relatedCustumer/getRelatedCustumers',
      getTotalPages: 'relatedCustumer/getTotalPages',
    }),
    dataTableItems() {
      return this.getRelatedCustumers.map(el => ({
        document: this.service.maskToDocument(el.document),
        seller: el.name && `${el.name.substr(0, 60)}`,
        balance: el.showBalance,
      }));
    },
    totalPages() {
      return parseInt(this.getTotalPages, 10);
    },
  },
  methods: {
    ...mapActions({ setRelatedCustumers: 'relatedCustumer/setRelatedCustumers' }),
    async populateTable() {
      await this.setRelatedCustumers({ page: 1, perPage: 10 });
    },
  },
  async created() {
    this.$bus.$on('tableVisible', (payload) => {
      this.showTable = payload;
    });
    await this.setRelatedCustumers({ page: 1, perPage: 10 });

    this.$bus.$on('paginationMySuppliers', async (payload) => {
      await this.setRelatedCustumers(payload);
    });
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/seller/mySuppliers.scss';
</style>
