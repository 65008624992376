const classTd = {
  aprovado: 'aprovado',
  reprovado: 'reprovado',
  pendente: 'pendente',
  elseNull: 'elseNull',
  action: 'action',
};
const tdValue = {
  aprovado: 1,
  reprovado: 2,
  pendente: 0,
  elseNull: null,
  action: 'action',
};

const tdLabels = {
  aprovado: 'Aprovado',
  reprovado: 'Reprovado',
  pendente: 'Pendente',
  elseNull: '-',
  action: 'VER',
};

const status = [
  {
    class: classTd,
    value: tdValue,
    label: tdLabels,
  },
];

export default status;
