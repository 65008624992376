<template>
  <v-row class="rowDialog">
    <v-dialog  v-model="dialog" :fullscreen="$vuetify.breakpoint.smAndDown"  max-width="430" >
      <template v-slot:activator="{ on }">
        <v-btn
          outlined
          rounded
          small
          color="#D70472"
          v-on="on"
          @click="openModal"
          class="btnEdit"
        >
          Editar
        </v-btn>
      </template>
      <v-card class="contentCard">
        <v-system-bar color="white" height="40">
          <v-spacer></v-spacer>
            <v-icon size="24" @click="dialog = false" v-ripple>
              mdi-close
            </v-icon>
        </v-system-bar>

        <v-card-title class="headline pt-0">
            Editar Fornecedor
        </v-card-title>
        <v-divider class="mx-4"></v-divider>
        <v-col>
          <v-alert class="alert" dense outlined v-if="error">{{error}}</v-alert>
        </v-col>
        <v-card-text>
         <v-container class="contentForm" v-if="showModal" fluid>
          <v-form  v-if="!IdsSuppliers" ref="form" v-model="isFormValid"  @submit.prevent="submit">
            <v-row v-if="!selectValue">
              <v-col cols="12" class="iconSearchFilters">
                <v-text-field
                  v-model="item.document"
                  label="CNPJ"
                  v-mask="'##.###.###/####-##'"
                  :rules="validationCNPJ"
                  placeholder="Digite o CNPJ"
                  hide-details="auto"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="iconSearchFilters">
                <v-text-field
                  v-model="item.seller"
                  label="Razão Social"
                  placeholder="Razão Social"
                  hide-details="auto"
                  readonly
                ></v-text-field>
              </v-col>

              <v-col  cols="12" class="mt-5">
                <v-select
                  class="statusOptions"
                  v-model="item.balance"
                  :items="statusOptions"
                  item-text="text"
                  item-value="value"
                  label="Mostrar Saldo"
                ></v-select>
              </v-col>
             </v-row>
          </v-form>
          </v-container>
          <v-row class="success-card" v-if="IdsSuppliers">
            <v-col cols="12">
              <img class="imgSuccess" src="@/assets/success.png" />
              <h5 class="succesTitle">Fornecedor editado com sucesso</h5>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="!IdsSuppliers"
            @click="closeModal"
            class="btnCancel">
            Cancelar
          </v-btn>
          <v-btn
            v-if="!IdsSuppliers"
            @click="sendRequest"
            class="btnRequest"
            id="testeQA"
            :loading="btnLoading"
            :disabled="!isFormValid">
            Salvar
          </v-btn>
          <button
            v-if="IdsSuppliers"
            @click="closeModal"
            class="btnClose">
            Fechar
          </button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { mask } from 'vue-the-mask';
import service from '../../../service';

export default {
  directives: {
    mask,
  },

  props: {
    item: {
      type: Object,
      required: true,
    },
    reloadGrid: {
      type: String,
      default: '',
      required: true,
    },
  },
  data() {
    return {
      btnLoading: false,
      showModal: true,
      dialog: false,
      singleStatus: true,
      isFormValid: false,
      selectValue: false,
      service,
      statusOptions: [
        { text: 'Sim', value: true },
        { text: 'Não', value: false },
      ],
    };
  },
  watch: {
    dialog(value) {
      if (value === false) {
        this.closeModal();
      }
    },
  },
  computed: {
    ...mapGetters({
      IdsSuppliers: 'relatedCustumer/getIdsSuppliers',
      error: 'relatedCustumer/getError',
    }),
    validationCNPJ() {
      return [
        value => !!value || 'CNPJ obrigatório',
        value => (value && value.length > 17) || 'Deve conter 14 números',
      ];
    },
  },
  methods: {
    ...mapActions({
      setIdsSuppliers: 'relatedCustumer/setIdsSuppliers',
      setRelatedCustumers: 'relatedCustumer/setRelatedCustumers',
    }),
    ...mapMutations({
      clearerror: 'relatedCustumer/CLEAR_ERROR',
      clearIdsSuppliers: 'relatedCustumer/CLEAR_IDS_SUPPLIERS',
    }),
    closeModal() {
      this.dialog = false;
      this.selectValue = false;
      this.optionsValue = '';
      this.showModal = false;
      this.document = '';
      this.showBalance = '';
      this.clearerror();
      this.clearIdsSuppliers();
    },
    openModal() {
      this.showModal = true;
    },
    async sendRequest() {
      this.btnLoading = true;
      if (this.$refs.form.validate()) {
        await this.setIdsSuppliers(
          {
            relatedCustomer: [
              {
                document: this.service.cleanDocument(this.item.document),
                showBalance: this.item.balance,
              },
            ],
          },
        );
      }
      this.btnLoading = false;
      this.$bus.$emit('reloadPagination', 1);
      this.$bus.$emit(this.reloadGrid, { page: 1 });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/seller/editSupplier.scss';
</style>
