<template>
  <v-row class="rowDialog">
    <v-dialog  v-model="dialog" :fullscreen="$vuetify.breakpoint.smAndDown"  max-width="430" >
      <template v-slot:activator="{ on }">
        <button class="btnNewRequest" @click="openModal" v-on="on">Adicionar Fornecedor</button>
      </template>
      <v-card class="contentCard">
        <v-system-bar color="white" height="40">
          <v-spacer></v-spacer>
            <v-icon size="24" @click="dialog = false" v-ripple>
              mdi-close
            </v-icon>
        </v-system-bar>
        <v-card-title class="headline pt-0">
            Adicionar Fornecedor
        </v-card-title>
          <v-divider class="mx-4"></v-divider>
          <v-col>
          <v-alert class="alert" dense outlined v-if="error">{{error}}</v-alert>
        </v-col>
        <v-card-text>
         <v-container class="contentForm" v-if="showModal" fluid>
          <v-form  v-if="!addProvider" ref="form" v-model="isFormValid"  @submit.prevent="submit">
            <v-row v-if="!selectValue">
              <v-col cols="12" class="iconSearchFilters">
                <v-text-field
                  v-model="document"
                  label="CNPJ"
                  v-mask="'##.###.###/####-##'"
                  :rules="validationCNPJ"
                  placeholder="Digite o CNPJ"
                  hide-details="auto"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="iconSearchFilters">
                <v-text-field
                  v-model="name"
                  label="Razão Social"
                  placeholder="Razão Social"
                  hide-details="auto"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col  cols="12" class="mt-5">
                <v-select
                  class="statusOptions"
                  v-model="showBalance"
                  label="Mostrar Saldo"
                  :items="items"
                  item-text="text"
                  item-value="value"
                  required
                ></v-select>
              </v-col>
             </v-row>
          </v-form>
          </v-container>
          <v-row  v-if="addProvider">
            <v-col cols="12" class="success-card">
              <img class="imgSuccess" src="@/assets/success.png" />
              <h5 class="succesTitle">Fornecedor incluido com sucesso</h5>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="!addProvider"
            @click="sendRequest"
            class="btnRequest"
            :loading="btnLoading"
            :disabled="!isFormValid">
            Salvar
          </v-btn>
          <button
            v-if="addProvider"
            @click="closeModal"
            class="btnClose">
            Fechar
          </button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { mask } from 'vue-the-mask';
import { VMoney } from 'v-money';
import service from '../../../service';

export default {
  directives: {
    mask,
    money: VMoney,
  },
  props: {
    reloadGrid: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      btnLoading: false,
      showModal: true,
      dialog: false,
      singleStatus: true,
      isFormValid: false,
      showBalance: true,
      document: '',
      name: '',
      selectValue: false,
      service,
      items: [
        { text: 'Sim', value: true },
        { text: 'Não', value: false },
      ],
    };
  },
  watch: {
    dialog(value) {
      if (value === false) {
        this.closeModal();
      }
    },
    document(value) {
      if (value.length === 18) {
        this.setSupplierName(this.service.cleanDocument(value));
      }
    },
    supplierName(name) {
      this.name = name;
    },
  },
  computed: {
    ...mapGetters({
      addProvider: 'relatedCustumer/getAddProvider',
      error: 'relatedCustumer/getError',
      supplierName: 'relatedCustumer/getSupplierName',
    }),
    validationCNPJ() {
      return [
        value => !!value || 'CNPJ obrigatório',
        value => (value && value.length > 17) || 'Deve conter 14 números',
      ];
    },
  },
  methods: {
    ...mapActions({
      setAddProvider: 'relatedCustumer/setAddProvider',
      setRelatedCustumers: 'relatedCustumer/setRelatedCustumers',
      setSupplierName: 'relatedCustumer/setSupplierName',
    }),
    ...mapMutations({
      clearerror: 'relatedCustumer/CLEAR_ERROR',
      clearAddProvider: 'relatedCustumer/CLEAR_ADD_PROVIDER',
    }),

    closeModal() {
      this.dialog = false;
      this.selectValue = false;
      this.optionsValue = '';
      this.showModal = false;
      this.document = '';
      this.name = '';
      this.showBalance = true;
      this.clearerror();
      this.clearAddProvider();
      this.setRelatedCustumers();
    },
    openModal() {
      this.showModal = true;
    },
    async sendRequest() {
      this.btnLoading = true;
      if (this.$refs.form.validate()) {
        await this.setAddProvider(
          {
            relatedCustomer: [
              {
                document: this.service.cleanDocument(this.document),
                showBalance: this.showBalance,
              },
            ],
          },
        );
      }
      this.btnLoading = false;
      this.$bus.$emit('reloadPagination', 1);
      this.$bus.$emit(this.reloadGrid, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/seller/newSupplier.scss';
</style>
